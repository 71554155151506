import React from 'react';
import { observer } from 'mobx-react';
import getDisplayName from 'src/utils/get-display-name';
import { getPSPDFKit } from 'src/utils/pspdfkit';

const MOVEMENT_TOLERANCE = 1; // in pixels

/**
 *  So how does movement work, you might ask after scrolling through the
 * labyrinth of pointerdown, pointerup, pointermove and pointerleave?
 *
 * Basically we have all those events attached to the select box that is
 * drawn on top of a selected annotation. Note that this select box is
 * displayed only if multiple annotations are selected. When only one is
 * selected, PSPDFKit deals with movement by itself.
 *
 *  On pointerdown we raise a flag to signal that movement *MAY* start, we
 * store the coordinates of where the event happened.
 *
 *  Now a few things can happen:
 *
 *   1. pointermove, in which case we calculate the offset by diffing the event
 * coordinates with the stored ones and we move all selected annotations by that
 * offset. By moving the annotations I mean the DOM nodes, not the actual
 * annotations.
 *   2. pointerup: we check were the event ocurred. If it is off from the origin
 * point were pointerdown occured, we assume there was movement and it finished
 * so we commit the new location of the annotations to PSPDFkit.
 * If it happened in the same place were the user triggered pointerdown, we
 * treat the event as a click and de-select the underlying annotation.
 *   3. pointerleave: If the pointer left the selection box we make sure all
 * movement logic is cancelled and that the new location is commited. This
 * can happen when the cursor goes off the iframe or if it is moved too fast.
 */

export default function selectable() {
  return (WrappedComponent) => {
    class Selectable extends WrappedComponent {
      selectableMounted = false;

      move = false;
      origin = null;
      prevPoint = false;

      selectBoxRef = null;

      unsetMoveState = () => {
        this.setState({
          move: false,
        });
        this.move = false;
        this.origin = null;
        this.prevPoint = null;
      };

      handlePointerDown = (event) => {
        event.stopPropagation();
        const { annotations } = this.props;
        annotations.hideProperties();
        // if the user is not using the shift-key we start listening
        // for movement and reposition the selected annotations accordingly
        if (!event.shiftKey) {
          this.setState({
            move: true,
          });

          this.move = true;
          this.origin = {
            x: event.clientX,
            y: event.clientY,
          };
          this.prevPoint = {
            x: event.clientX,
            y: event.clientY,
          };
        }
      };

      handlePointerUp = async (event) => {
        const { annotations, annotation } = this.props;
        const { pspdfkitInstance, isDesignMode } = annotations;
        annotations.showProperties();
        if (!pspdfkitInstance || !isDesignMode || event.shiftKey) {
          this.unsetMoveState();
          return;
        }

        if (
          !this.origin ||
          (Math.abs(event.clientX - this.origin.x) < MOVEMENT_TOLERANCE &&
            Math.abs(event.clientY - this.origin.y) < MOVEMENT_TOLERANCE)
        ) {
          setTimeout(() => {
            pspdfkitInstance.setSelectedAnnotation(annotation.id);
            annotations.setSelectedAnnotations([annotation]);
          }, 0);
          this.unsetMoveState();
          return;
        }

        this.commitLocation().then(() => {
          if (annotations.selectedAnnotations.length === 1) {
            setTimeout(() => {
              pspdfkitInstance.setSelectedAnnotation(annotation.id);
            }, 0);
          }
          this.unsetMoveState();
        });
      };

      handlePointerLeave = (event) => {
        const { annotations, annotation } = this.props;
        const { pspdfkitInstance, isDesignMode } = annotations;
        if (!this.selectableMounted || !isDesignMode) {
          return;
        }

        if (
          this.origin &&
          (Math.abs(event.clientX - this.origin.x) > MOVEMENT_TOLERANCE ||
            Math.abs(event.clientY - this.origin.y) > MOVEMENT_TOLERANCE)
        ) {
          this.commitLocation().then(() => {
            if (annotations.selectedAnnotations.length === 1) {
              setTimeout(() => {
                pspdfkitInstance.setSelectedAnnotation(annotation.id);
              }, 0);
            }
            this.unsetMoveState();
          });
        }
      };

      handlePointerMove = (event) => {
        const { annotations } = this.props;
        const { pspdfkitInstance, isDesignMode } = annotations;

        if (this.move !== true) {
          return;
        }

        if (!isDesignMode) {
          return;
        }

        const prevPoint = this.prevPoint || {};
        const offset = {
          x: event.clientX - prevPoint.x,
          y: event.clientY - prevPoint.y,
        };

        if (Math.abs(offset.x) < 1 && Math.abs(offset.y) < 1) {
          return;
        }

        this.prevPoint = {
          x: event.clientX,
          y: event.clientY,
        };

        const selectedAnnotations = annotations.selectedAnnotations;
        const nodes = selectedAnnotations.map(({ id }) => {
          return pspdfkitInstance.contentDocument.getElementById(
            `pdf-annotation-${id}`
          ).parentNode;
        });

        // Reposition the DOM nodes taking into account the width and
        // height of the page, making sure the node doesn't go out of bounds
        const pageNode =
          pspdfkitInstance.contentDocument.querySelector('.PSPDFKit-Page');
        const pageRect = pageNode.getBoundingClientRect();
        nodes.forEach((n) => {
          const rect = n.getBoundingClientRect();
          const maxX = pageRect.width - rect.width;
          const maxY = pageRect.height - rect.height;
          const top = Math.min(
            maxY,
            Math.max(0, parseFloat(n.style.top) + offset.y)
          );
          const left = Math.min(
            maxX,
            Math.max(0, parseFloat(n.style.left) + offset.x)
          );
          n.style.top = `${top}px`;
          n.style.left = `${left}px`;
        });
      };

      getClientRect = (annotationId) => {
        /* This hacky thing is because webkit browsers don't compute
         * getBoundingClientRect appropriately when the element
         * has transformations.
         * A clone of the element is created and transformed to a scale
         * of 1. Providing mostly accurate results except for width and height
         */
        const {
          annotations: { pspdfkitInstance },
        } = this.props;
        const annotationNode = pspdfkitInstance.contentDocument.getElementById(
          `pdf-annotation-${annotationId}`
        );
        const parentClone = annotationNode.parentNode.cloneNode(false);

        annotationNode.parentNode.parentNode.appendChild(parentClone);
        parentClone.style.transform = 'scale(1)';
        const rect = parentClone.getBoundingClientRect();
        parentClone.remove();
        return rect;
      };

      commitLocation = async () => {
        const { annotations } = this.props;
        const { pspdfkitInstance, selectedAnnotations } = annotations;

        const updatedAnnotations = selectedAnnotations.map((annotation) => {
          const page = annotation.pageIndex;
          const rect = this.getClientRect(annotation.id);
          const annotationRect =
            pspdfkitInstance.transformContentClientToPageSpace(
              new (getPSPDFKit().Geometry.Point)({
                x: rect.x,
                y: rect.y,
              }),
              page
            );
          return annotation.set(
            'boundingBox',
            annotation.boundingBox.setLocation(
              new (getPSPDFKit().Geometry.Point)({
                x: annotationRect.x,
                y: annotationRect.y,
              })
            )
          );
        });
        await pspdfkitInstance.update(updatedAnnotations);
      };

      componentDidMount() {
        if (super.componentDidMount) {
          super.componentDidMount();
        }

        this.selectableMounted = true;
      }

      componentWillUnmount() {
        if (super.componentWillUnmount) {
          super.componentWillUnmount();
        }
        this.selectableMounted = false;
      }

      render() {
        const { annotation, annotations, ghost } = this.props;
        const { move } = this.state || {};
        const {
          pspdfkitInstance,
          selectedAnnotations,
          isDesignMode,
          isSignMode,
          isFillMode,
        } = annotations;

        const hidden = annotations.getIsExcluded(annotation);

        // HACK: When moving the annotation we need to make sure it gets
        // on top of any others as the event handlers are attached to sub-nodes
        // of the parent annotation node (not the document or window).
        // To do that we need to go up the tree to the PSPDFKit element that
        // holds the annotation root node (aka .pdf-annotation-root).
        setTimeout(() => {
          // since this happens at the end of the event loop we need to check
          // if the component is still mounted and that we have a ref
          if (!this.selectableMounted || !this.selectBoxRef) {
            return;
          }
          try {
            // select-box -> react node -> annotation root -> pspdfkit container
            const node = this.selectBoxRef.parentNode.parentNode.parentNode;
            let zIndex;
            if (move) {
              zIndex = '1';
            } else if (hidden) {
              zIndex = '-1';
            } else {
              zIndex = 'auto';
            }
            node.style.zIndex = zIndex;
          } catch (err) {
            // eslint-disable-next-line no-empty
          }
        }, 0);

        if (hidden) {
          return (
            <div>
              <div
                ref={(n) => {
                  this.selectBoxRef = n;
                }}
              />
            </div>
          );
        }

        const component = super.render();

        // we don't add anything to ghost annotations
        if (ghost || isSignMode || isFillMode || !component) {
          return component;
        }

        const { children } = component.props;
        const newProps = {
          ...component.props,
          onPointerDown: (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.persist();

            if (!pspdfkitInstance) {
              return;
            }

            if (!isDesignMode) {
              annotations.selectSingleAnnotation(annotation);
              return;
            }

            if (!this.selectableMounted) {
              return;
            }

            if (!event.shiftKey) {
              pspdfkitInstance.setSelectedAnnotation(null);
              annotations.selectSingleAnnotation(annotation);
              this.handlePointerDown(event);
            } else {
              if (annotations.selectedAnnotations.length === 1) {
                pspdfkitInstance.setSelectedAnnotation(null);
              }
              annotations.addSelectedAnnotation(annotation);
            }
          },
        };

        // we only show custom selection boxes if there is no
        // visible pspdfkit selected annotation
        const selected =
          pspdfkitInstance &&
          !pspdfkitInstance.getSelectedAnnotation() &&
          (selectedAnnotations || [])
            .filter(Boolean)
            .map(({ id }) => id)
            .includes(annotation.id);

        const selectNode = (
          <div
            key="select-box"
            role="button"
            ref={(n) => {
              this.selectBoxRef = n;
            }}
            className={
              'pdf-annotation-select-box ' +
              `${move ? 'pdf-annotation-select-box--dragging' : ''}`
            }
            style={{
              display: selected ? 'block' : 'none',
            }}
            onPointerDownCapture={this.handlePointerDown}
            onPointerMove={this.handlePointerMove}
            onPointerUp={this.handlePointerUp}
            onPointerLeave={this.handlePointerLeave}
          >
            <div className="pdf-annotation-select-box__border">
              <div className="pdf-annotation-select-box__anchor pdf-annotation-select-box__anchor--top-right" />
              <div className="pdf-annotation-select-box__anchor pdf-annotation-select-box__anchor--top-left" />
              <div className="pdf-annotation-select-box__anchor pdf-annotation-select-box__anchor--bottom-right" />
              <div className="pdf-annotation-select-box__anchor pdf-annotation-select-box__anchor--bottom-left" />
            </div>
          </div>
        );

        const newChildren = [];
        if (children instanceof Array) {
          newChildren.push(...children);
        } else {
          newChildren.push(children);
        }
        newChildren.push(selectNode);

        return React.cloneElement(component, newProps, newChildren);
      }
    }

    Selectable.displayName = `Selectable(${getDisplayName(WrappedComponent)})`;
    return observer(Selectable);
  };
}
