

import React, { Component } from 'react';
import { Avatar, Select } from 'antd';
import classNames from 'classnames';
import toPairs from 'lodash/toPairs';
import PropTypes from 'prop-types';
import {
  BUYER_AGENT,
  BUYER_AGENT2,
  CO_BUYING2,
  CO_BUYING3,
  CO_BUYING4,
  CO_BUYING5,
  BUYER_BROKER,
  BUYER_ROLES,
  LISTING_AGENT,
  LISTING_AGENT2,
  CO_LISTING2,
  CO_LISTING3,
  CO_LISTING4,
  CO_LISTING5,
  LISTING_BROKER,
  SELLER_ROLES,
  getRoleLabelsForTransaction,
} from 'src/models/transactions/roles';
import { getColor } from 'src/utils/get-color';

const clsPrefix = 'tab-recipient-role-select';

export const REGULAR_ROLES = [
  ...BUYER_ROLES,
  BUYER_AGENT,
  BUYER_AGENT2,
  CO_BUYING2,
  CO_BUYING3,
  CO_BUYING4,
  CO_BUYING5,
  BUYER_BROKER,
  ...SELLER_ROLES,
  LISTING_AGENT,
  LISTING_AGENT2,
  CO_LISTING2,
  CO_LISTING3,
  CO_LISTING4,
  CO_LISTING5,
  LISTING_BROKER,
];

export const EXTRA_ROLES = {
  FILLER: 'Filler (Avid only)',
  PLACEHOLDER1: 'Placeholder 1',
  PLACEHOLDER2: 'Placeholder 2',
  PLACEHOLDER3: 'Placeholder 3',
  PLACEHOLDER4: 'Placeholder 4',
  PLACEHOLDER5: 'Placeholder 5',
  PLACEHOLDER6: 'Placeholder 6',
};

export const RECIPIENT_COLOR_MAP = {
  BUYER: getColor('light-yellow-color'),
  BUYER2: '#FFD051',
  BUYER3: '#FFB014',
  BUYER4: '#FFA366',
  BUYER_AGENT: '#CFBBDB',
  BUYER_AGENT2: '#9986A4',
  CO_BUYING2: '#B7AABF',
  CO_BUYING3: '#AD9EB6',
  CO_BUYING4: '#A392AD',
  CO_BUYING5: '#897893',
  BUYER_BROKER: '#E59191',
  LISTING_AGENT: '#DBE49F',
  LISTING_AGENT2: '#B5C83B',
  CO_LISTING2: '#CBD875',
  CO_LISTING3: '#BCCD4E',
  CO_LISTING4: '#A2B435',
  CO_LISTING5: '#90A02F',
  LISTING_BROKER: '#AAE1C0',
  SELLER: '#A5D1F2',
  SELLER2: '#67B1E9',
  SELLER3: '#1F8ADB',
  SELLER4: '#6C82C9',
  FILLER: '#D2DFEC',
  PLACEHOLDER1: '#F0F0F0',
  PLACEHOLDER2: '#DCDCDC',
  PLACEHOLDER3: '#D0D0D0',
  PLACEHOLDER4: '#BEBEBE',
  PLACEHOLDER5: '#A9A9A9',
  PLACEHOLDER6: '#989898',
};

/*
 * This component is used by the admin esign page
 */
export default class RecipientRoleSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    transaction: PropTypes.object,
    disabled: PropTypes.bool,
  };

  get labelByRoles() {
    const { transaction } = this.props;
    const labelByRoles = getRoleLabelsForTransaction(transaction);

    return {
      ...Object.fromEntries(
        Object.entries(labelByRoles).filter(([key]) => {
          return REGULAR_ROLES.includes(key);
        })
      ),
      ...EXTRA_ROLES,
    };
  }

  get options() {
    const options = { '': { label: 'Unassigned' } };

    toPairs(this.labelByRoles).forEach(([key, label]) => {
      options[key] = {
        label,
        color: RECIPIENT_COLOR_MAP[key],
      };
    });

    return options;
  }

  renderOptions = () => {
    return toPairs(this.options).map(([key, { label, color }]) => {
      return (
        <Select.Option
          key={key}
          value={key}
          style={{
            height: '100%',
            lineHeight: '100%',
          }}
        >
          <div className={`${clsPrefix}__value-container`}>
            <Avatar
              style={{
                backgroundColor: color,
              }}
              size="small"
            />
            {label}
          </div>
        </Select.Option>
      );
    });
  };

  render() {
    const { value, style, className, label, onChange, disabled } = this.props;

    return (
      <div>
        {label && <p>{label}</p>}
        <Select
          dropdownClassName={`${clsPrefix}__dropdown`}
          className={classNames(clsPrefix, className)}
          style={style}
          value={value}
          onChange={(role) => {
            if (onChange) {
              onChange(role);
            }
          }}
          disabled={disabled}
        >
          {this.renderOptions()}
        </Select>
      </div>
    );
  }
}
