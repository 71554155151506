

import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const clsPrefix = 'pdf-annotate-text-redact';

@observer
export default class PdfRedactText extends Component {
  static propTypes = {
    annotations: PropTypes.object.isRequired,
    annotation: PropTypes.object.isRequired,
  };

  handlePointerDown = (event) => {
    event.stopPropagation();
    const { annotations, annotation } = this.props;
    const name = lodashGet(annotation, 'customData.name');
    if (name) {
      annotations.selectByName(name);
      return;
    }
    setTimeout(() => {
      annotations.setSelectedAnnotations([annotation]);
    }, 0);
  };

  render() {
    const { annotations, annotation } = this.props;
    const { pspdfkitInstance, selectedAnnotations } = annotations;

    const selected =
      pspdfkitInstance &&
      (selectedAnnotations || []).find(({ id }) => id === annotation.id);

    return (
      <div className={clsPrefix} onPointerDownCapture={this.handlePointerDown}>
        <div
          className={`${clsPrefix}__select-border`}
          style={{
            display: selected ? 'block' : 'none',
          }}
        />
      </div>
    );
  }
}
