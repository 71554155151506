import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import selectable from './selectable';
import { RECIPIENT_COLOR_MAP } from './recipient-role-select';
import { getColor } from 'src/utils/get-color';

const clsPrefix = 'pdf-annotation-tab';

@selectable()
export default class Tab extends Component {
  static propTypes = {
    annotation: PropTypes.object,
    annotations: PropTypes.object,
    children: PropTypes.any.isRequired,
  };

  get annotation() {
    const { annotations, annotation: _annotation } = this.props;
    return annotations.getAnnotationById(_annotation.id) ?? _annotation;
  }

  render() {
    const { children } = this.props;
    const { customData } = this.annotation;
    const { optional, recipientRole, recipientColor, type } = customData;
    const baseColor =
      RECIPIENT_COLOR_MAP[recipientRole] ||
      recipientColor ||
      RECIPIENT_COLOR_MAP.BUYER;
    const backgroundColor = `${baseColor}CC`;

    return (
      <div
        className={classNames(clsPrefix, {
          [`${clsPrefix}--${type}`]: type,
          [`${clsPrefix}--${recipientRole}`]: recipientRole,
        })}
        style={{
          backgroundColor,
          ...(optional
            ? {
                backgroundClip: 'border-box',
                borderColor: getColor('app-alto'),
              }
            : {}),
        }}
      >
        <div className={`${clsPrefix}__container`}>
          <div className={`${clsPrefix}__inner`}>{children}</div>
        </div>
      </div>
    );
  }
}
