
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {
  TEXT_FIELD_FONT_SIZE,
  TEXT_FIELD_MIN_FONT_SIZE,
  TEXT_FIELD_OVERFLOW_ERROR_TEXT,
  TEXT_FIELD_TOA_TEXT,
} from 'src/components/documents/pspdfkit/annotations/constants';
import {
  consumeLine,
  getTextChunks,
} from 'src/components/documents/pspdfkit/text-splits';
import PspdfkitPopover from 'src/components/reform/input-widget/pspdfkit-popover';
import type PdfAnnotationsStore from 'src/stores/pdf-annotations-store';
import pdfFormField from './pdf-form-field';
import OcrZoneIndicator from './ocr-zone-indicator';

const clsPrefix = 'pdf-annotation-text';

const TOA_SPACE = 40;

interface TextProps {
  annotation: Annotation;
  annotations: PdfAnnotationsStore;
  ghost: boolean;
}

interface Annotation {
  customData: CustomData;
  boundingBox: BoundingBox;
}
interface BoundingBox {
  width: number;
  height: number;
}
interface CustomData {
  fontSize: number;
  horizontalAlign: string;
  defaultValue?: string;
  index: number;
  formFieldName: string;
  canWrap: boolean;
}

// NB: this.annotation comes from jsapp/src/components/documents/pspdfkit/annotations/pdf-form-field.js
@pdfFormField()
export default class Text extends Component<TextProps> {
  customData(): Partial<CustomData> {
    return this.annotation?.customData ?? {};
  }

  horizontalAlign() {
    const { horizontalAlign } = this.customData();
    const defaultAlign =
      this.relatedAnnotations().length <= 1 ? 'center' : 'left';

    return horizontalAlign ?? defaultAlign;
  }

  // Regardless of TOA enabled/disabled, single or multiline, shrink the text if necessary. Never go smaller than the min.
  // If there's no custom font size, start at default 8 and shrink as necessary to fit (also grow back if space frees up).
  // Otherwise, if there is a custom font size:
  // If custom font size is smaller than default, we still prevent it from ever getting smaller than the min.
  // If custom font size is greater than default, this just affects the starting point for the font size and
  // still shrink all the way down to the min.
  fontSize() {
    // Shrinking isn't implemented for design mode (esign and admin editing), so just stay at TEXT_FIELD_FONT_SIZE
    if (this.props.annotations.isDesignMode) {
      return TEXT_FIELD_FONT_SIZE;
    }

    let fontSize = this.customData().fontSize ?? TEXT_FIELD_FONT_SIZE;
    let res = true;
    while (fontSize >= TEXT_FIELD_MIN_FONT_SIZE && res) {
      const remainder = this.getTextChunks(fontSize)[1];

      res = this.chunkValues(fontSize).length && remainder !== '';

      if (res) {
        fontSize -= 1;
      }
    }

    return Math.max(fontSize, TEXT_FIELD_MIN_FONT_SIZE);
  }

  isReadOnly() {
    const { annotations } = this.props;
    return annotations.isReadOnly(this.annotation);
  }

  isLinked() {
    return this.props.annotations.isLinked(this.annotation);
  }

  isComputedOnly() {
    return this.props.annotations.isComputedOnly(this.annotation);
  }

  isHighlighted() {
    return this.props.annotations.isHighlighted(this.annotation);
  }

  value() {
    const { isFillMode, valueOf } = this.props.annotations;
    /* eslint-disable no-shadow */
    return isFillMode || Boolean(this.customData().defaultValue)
      ? valueOf(this.annotation) ?? this.customData().defaultValue
      : '';
  }

  index() {
    return this.customData().index ?? 0;
  }

  relatedAnnotations(): Annotation[] {
    const { annotations } = this.props;
    const { formFieldName } = this.customData();

    if (!formFieldName) {
      return [];
    }

    return annotations.getAnnotationsByFormField(formFieldName);
  }

  chunkValues(fontSize: number) {
    if (this.value() && this.relatedAnnotations()[this.index()]) {
      const [chunkValues] = getTextChunks(
        this.value(),
        this.relatedAnnotations().map(({ boundingBox: { width } }) => width),
        fontSize
      );

      return chunkValues;
    }

    return [];
  }

  // This is used for wrapped text only.
  getMultilineTextChunks(fontSize: number) {
    const toaSpace = this.props.annotations.disableToa ? 0 : TOA_SPACE;
    const boxWidth = this.annotation.boundingBox.width;

    // Because it's a wrapped line, we pass 2 widths to this._getTextChunks to mimic
    // as if there were two separate lines with the same annotation width.
    // Only the second line will have the TOA space.
    const wrappedWidths = [boxWidth, boxWidth - toaSpace];
    return getTextChunks(this.value(), wrappedWidths, fontSize);
  }

  getTextChunks(fontSize: number) {
    const boxWidths = this.relatedAnnotations().map(
      ({ boundingBox: { width } }) => width
    );
    return getTextChunks(this.value(), boxWidths, fontSize);
  }

  isWrapped() {
    if (!this.customData().canWrap) {
      return false;
    }

    // canWrap should not be true for multilines
    // see src/components/documents/pspdfkit/annotation-properties/field-options.js preventing the flag if siblings exist.
    if (this.relatedAnnotations().length > 1) {
      return false;
    }

    // If there's more than one text chunk and there are no other related annotations, it means we can't fit on our one line
    // without wraping.
    // (getTextChunks sees how much can fit based on lines available from related annoatations)
    return this.getTextChunks(this.fontSize())[1] !== '';
  }

  requiresToa() {
    const { annotations } = this.props;

    if (annotations.disableToa) {
      return false;
    }

    if (this.isWrapped()) {
      return this.wrappedTextOverflows(this.fontSize());
    }

    const remainder = this.getTextChunks(this.fontSize())[1];
    return (
      this.chunkValues(this.fontSize()).length &&
      remainder !== '' &&
      this.index() === this.relatedAnnotations().length - 1
    );
  }

  showOverflowError() {
    const { annotations } = this.props;

    if (!annotations.disableToa) {
      return false;
    }

    if (this.isWrapped()) {
      return this.wrappedTextOverflows(this.fontSize());
    }

    const remainder = this.getTextChunks(this.fontSize())[1];

    return Boolean(
      this.chunkValues(this.fontSize()).length && remainder !== ''
    );
  }

  missingFillCondition() {
    return this.props.annotations.isMissingField(
      this.customData().formFieldName
    );
  }

  isFillConditionSelected() {
    const { annotations } = this.props;
    const { selectedMissingFillCondition } = annotations;
    return (
      this.missingFillCondition() &&
      selectedMissingFillCondition?.fieldName ===
        this.customData().formFieldName
    );
  }

  wrappedTextOverflows(fontSize: number) {
    const leftoverText = this.getMultilineTextChunks(fontSize)[1];
    return (
      leftoverText !== '' &&
      this.index() === this.relatedAnnotations().length - 1
    );
  }

  wrappedText() {
    const remainder = this.getMultilineTextChunks(this.fontSize())[1];
    if (remainder === '') {
      return this.value();
    }

    // If there was overflow, return the current value minus the remainder.
    // We can't just use the chuncks returned from this.getMultilineTextChunks and join them because they strip whitespace between lines.
    return this.value().slice(0, -remainder.length);
  }

  cleanValue() {
    if (this.isWrapped()) {
      return this.wrappedText();
    }
    const chunk = this.chunkValues(this.fontSize())[this.index()] ?? '';

    if (this.requiresToa()) {
      return consumeLine(
        chunk,
        this.relatedAnnotations()[this.index()].boundingBox.width - TOA_SPACE,
        TEXT_FIELD_MIN_FONT_SIZE
      )[0];
    }

    return chunk;
  }

  classNames() {
    const { annotations } = this.props;

    return classNames(
      clsPrefix,
      `${clsPrefix}--align-${this.horizontalAlign()}`,
      {
        [`${clsPrefix}--fill-mode`]: annotations.isFillMode,
        [`${clsPrefix}--fill-condition`]: this.missingFillCondition(),
        [`${clsPrefix}--fill-condition-selected`]:
          this.isFillConditionSelected(),
        [`${clsPrefix}--readonly`]: this.isReadOnly(),
        [`${clsPrefix}--unlinked`]:
          !this.isReadOnly() && this.isLinked() === false,
        [`${clsPrefix}--computed-only`]:
          !this.isReadOnly() && this.isComputedOnly(),
        [`${clsPrefix}--highlighted`]: this.isHighlighted(),
      }
    );
  }

  lineHeight() {
    if (!this.isWrapped()) {
      return 14 + (TEXT_FIELD_FONT_SIZE - this.fontSize());
    }

    return this.fontSize();
  }

  errorIconPlacement() {
    return this.customData().horizontalAlign !== 'right' ? 'left' : 'right';
  }

  renderOverflowErrorMsg() {
    // only show overflow error message for the last annotation within a group of related annotations
    if (
      this.index() !== this.relatedAnnotations().length - 1 ||
      !this.showOverflowError()
    ) {
      return null;
    }

    return (
      <div
        className={classNames(
          `${clsPrefix}__error`,
          `${clsPrefix}__error--${this.errorIconPlacement()}`
        )}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        role="status"
      >
        <PspdfkitPopover
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0.2}
          content={
            <div className={`${clsPrefix}__error__text`}>
              {TEXT_FIELD_OVERFLOW_ERROR_TEXT}
            </div>
          }
          className={`${clsPrefix}__error__popover`}
          trigger="hover"
        >
          <ExclamationCircleOutlined className={`${clsPrefix}__error__icon`} />
        </PspdfkitPopover>
      </div>
    );
  }

  render() {
    return (
      <div className={this.classNames()}>
        <OcrZoneIndicator {...this.props} />
        {this.cleanValue() && (
          <div
            className={classNames(
              `${clsPrefix}__value`,
              this.isWrapped() && `${clsPrefix}__value--wrapped`,
              {
                [`${clsPrefix}__value--error`]: this.showOverflowError(),
              }
            )}
            style={{
              fontSize: `${this.fontSize()}px`,
              lineHeight: `${this.lineHeight()}px`,
            }}
          >
            {this.cleanValue()}
            {this.requiresToa() && (
              <span className={`${clsPrefix}__toa-marker`}>
                {' '}
                {TEXT_FIELD_TOA_TEXT}
              </span>
            )}
          </div>
        )}
        {this.renderOverflowErrorMsg()}
      </div>
    );
  }
}
