

import React, { Component } from 'react';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import selectable from './selectable';

const clsPrefix = 'pdf-annotate-stamp';
const clsPrefixShell = 'pdf-annotate-stamp-shell';

const SignatureBox = ({ recipientRole }) => {
  return (
    <div className={`${clsPrefixShell}__signature-box`}>
      <span>{recipientRole}</span>
      <span>Date</span>
    </div>
  );
};

SignatureBox.propTypes = {
  recipientRole: PropTypes.string,
};

export const PdfStampShell = ({
  children,
  recipientRole = 'Buyer',
  className = null,
}) => {
  let displayRole = 'Buyer';
  if (recipientRole === 'SELLER') {
    displayRole = 'Seller';
  }
  return (
    <div className={classNames(clsPrefixShell, className)}>
      {children}
      <SignatureBox recipientRole={displayRole} />
      <SignatureBox recipientRole={displayRole} />
    </div>
  );
};

PdfStampShell.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  recipientRole: PropTypes.string,
};

@selectable()
export default class PdfStamp extends Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    annotations: PropTypes.object.isRequired,
  };

  get annotation() {
    const { annotations, annotation } = this.props;
    return annotations.getAnnotationById(annotation.id);
  }

  render() {
    const customData = this.annotation?.customData ?? {};
    const exportValue = lodashGet(customData, 'exportValue', 'Received');
    const recipientRole = lodashGet(customData, 'recipientRole');
    return (
      <div className={clsPrefix}>
        <PdfStampShell recipientRole={recipientRole}>
          <div className={`${clsPrefix}__header`}>{exportValue}</div>
        </PdfStampShell>
      </div>
    );
  }
}
