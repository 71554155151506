import React from 'react';
import classNames from 'classnames';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { computed, makeObservable } from 'mobx';
import AnchorButton from 'src/components/common/anchor-button';
import AppIcon from 'src/components/common/app-icon';
import PspdfkitPopover from 'src/components/reform/input-widget/pspdfkit-popover';
import {
  FORM_NAMESPACE,
  TRANSACTION_NAMESPACE,
} from 'src/stores/pdf-annotations-store';
import { getColor } from 'src/utils/get-color';
import getDisplayName from 'src/utils/get-display-name';
import linkRequiresProperty from 'src/utils/link-requires-property';
import { TEXT_RENDER_TYPES } from './constants';
import { insertAnnotations } from './inserts';
import { RECIPIENT_COLOR_MAP } from './recipient-role-select';
import selectable from './selectable';

const DEFAULT_BG_COLOR = '#aaaaffcc';

const highlightClsPrefix = 'pdf-annotation-highlight';
const addClsPrefix = 'pdf-annotation-append';
const linkClsPrefix = 'pdf-annotation-link';
const linkMoreClsPrefix = 'pdf-annotation-more-link';
const unlinkModalPrefix = 'pdf-annotation-unlink-modal';

export default function pdfFormField(options = {}) {
  const optionsWithDefaults = defaults(options, {
    showIndex: true,
    showAddButton: true,
  });

  return (WrappedComponent) => {
    class PdfFormField extends WrappedComponent {
      constructor(props) {
        super(props);
        makeObservable(this);
      }

      /* Because PSPDFKit annotations are immutable the reference in props
       * can be outdated. Also PSPDFKit renders and re-renders the node
       * containing annotation widgets as it wills when it wills without letting us
       * control it, which is what causes the reference in props to get
       * outdated to being with.
       *
       * To prevent that, we instead rely on the annotations we have in the
       * store which should be up-to-date. We only fallback to the prop ref
       * if the store doesn't have our annotation (can happen if it was deleted
       * and this component didn't hear about it yet).
       */
      @computed
      get annotation() {
        const { annotations, annotation } = this.props;
        return annotations.getAnnotationById(annotation.id) || annotation;
      }

      handleAddButtonClick = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const { annotations } = this.props;
        const type = get(this.annotation, 'customData.type');
        const { pspdfkitInstance } = annotations;
        const referenceAnnotation = annotations.getAnnotationById(
          this.annotation.id
        );

        const conf = {
          store: annotations,
          type: this.annotation.customData.type,
          pspdfkitInstance,
          referenceAnnotations: [referenceAnnotation],
          customData: referenceAnnotation.customData,
          defaultValue: referenceAnnotation.customData?.defaultValue,
          optional: referenceAnnotation.customData?.optional,
        };
        if (type === 'checkbox') {
          Object.assign(conf, {
            customData: {
              recipientRole: referenceAnnotation.customData.recipientRole,
              recipientColor: referenceAnnotation.customData.recipientColor,
            },
            recipientKey: referenceAnnotation.customData.formFieldOwnerId,
          });
        } else if (type === 'dropdown') {
          Object.assign(conf, {
            customData: omit(referenceAnnotation.customData, ['formFieldName']),
            keepParams: true,
          });
        } else {
          Object.assign(conf, {
            indexOffset: referenceAnnotation.customData.index + 1,
            amount: 1,
          });
        }
        const newInserts = await insertAnnotations(conf);
        const first =
          newInserts instanceof Array ? newInserts.shift() : newInserts;
        pspdfkitInstance.setSelectedAnnotation(first.id);
        annotations.setSelectedAnnotations([first]);
      };

      renderFillMode(component) {
        const { annotations, hideMoreIcon } = this.props;
        const { pspdfkitInstance } = annotations;
        const { customData } = this.annotation;
        const { children } = component.props;

        const newChildren = [];
        if (children instanceof Array) {
          newChildren.push(...children);
        } else {
          newChildren.push(children);
        }
        const isLinked = annotations.isLinked(this.annotation);
        const boundOutput = annotations.getBoundOutput(this.annotation);
        const isReadOnly = annotations.isReadOnly(this.annotation);

        if (
          !isReadOnly &&
          !hideMoreIcon &&
          TEXT_RENDER_TYPES.includes(customData?.type)
        ) {
          newChildren.push(
            this.renderMoreIcon(boundOutput, annotations, isLinked)
          );
        }

        const eventTriggerer = TEXT_RENDER_TYPES.includes(
          this.annotation.customData.type
        )
          ? 'onClick'
          : 'onMouseUp';

        const newProps = {
          ...component.props,
          [eventTriggerer]: () => {
            if (!pspdfkitInstance) {
              return;
            }

            pspdfkitInstance.contentDocument.dispatchEvent(
              new CustomEvent('pdf-annotation-click', {
                detail: {
                  annotationId: this.annotation.id,
                  formFieldName: (customData || {}).formFieldName || '',
                  type: (customData || {}).type || '',
                },
              })
            );
          },
        };
        return React.cloneElement(component, newProps, newChildren);
      }

      renderHighlight({ highlighted, mod } = {}) {
        const { customData } = this.annotation;

        return (
          <div
            key="highlight"
            className={classNames(highlightClsPrefix, {
              [`${highlightClsPrefix}--${mod}`]: !!mod,
            })}
            style={{
              display: highlighted ? 'block' : 'none',
            }}
          >
            {optionsWithDefaults.showIndex && (
              <div key="index" className={`${highlightClsPrefix}__index`}>
                {customData.index !== undefined ? customData.index + 1 : '?'}
              </div>
            )}
          </div>
        );
      }

      renderAddButton({ showAddButton } = {}) {
        return (
          <div
            key="append-button"
            className={addClsPrefix}
            style={{
              display: showAddButton ? 'block' : 'none',
            }}
          >
            <button
              type="button"
              onPointerDownCapture={this.handleAddButtonClick}
              className={`${addClsPrefix}__button`}
            >
              <AppIcon
                type="antd"
                name="plus"
                className={`${addClsPrefix}__icon`}
              />
            </button>
          </div>
        );
      }

      renderLinkIcon(broken = false) {
        return (
          <AppIcon
            key="link-icon"
            type="feather"
            name={broken ? 'zap-off' : 'link'}
            className={classNames(
              `${linkClsPrefix}`,
              `${linkClsPrefix}--right`,
              {
                [`${linkClsPrefix}--broken`]: broken,
              }
            )}
          />
        );
      }

      renderMoreIcon(boundOutput, annotations, isLinked) {
        const customData = get(this.annotation, 'customData', {});
        const linkId = get(customData, 'formFieldLinkId', '');
        const namespace = get(customData, 'formFieldLinkNamespace', 'form');
        const horizontalAlign = get(customData, 'horizontalAlign', 'left');
        const linkedOutput = get(boundOutput, 'linkedOutput');
        const linkLabel = get(customData, 'formFieldLabel', '');
        const fieldName = customData?.formFieldName;
        const transaction = annotations.transaction;

        const missingFillCondition = annotations.isMissingField(fieldName);

        const isTransaction = namespace === 'transaction';
        const left = horizontalAlign === 'right';
        const typeDescription = isTransaction
          ? null
          : linkedOutput && linkedOutput.normalizedKind;

        const isName = Boolean(
          isLinked && isTransaction && linkedOutput?.isName
        );
        const primaryAgent = transaction.parties.primaryAgent;
        const restrictPropertyField =
          // Only restrict for purchase addressless transactions
          transaction.isPurchase &&
          !transaction.hasAddress &&
          // Only restrict property related fields linked to the transaction
          isTransaction &&
          linkRequiresProperty(linkId) &&
          // Only restrict if the form is linked to the main transaction namespace
          Object.keys(annotations.boundForm.dependencies).includes(
            TRANSACTION_NAMESPACE
          );

        let linkStatusDescription;
        let linkStatusAction;
        let unlinkedMsg;
        if (isLinked && isTransaction) {
          linkStatusDescription =
            linkLabel || 'This is linked to a transaction field';
          linkStatusAction = 'Unlink field';
        } else if (isLinked && typeDescription) {
          linkStatusDescription = `This is a ${typeDescription} field`;
          linkStatusAction = 'Free form type';
        } else if (isLinked) {
          linkStatusDescription = 'This field has special behavior';
          linkStatusAction = 'Remove field behavior';
        } else if (restrictPropertyField) {
          linkStatusDescription = linkLabel || null;
          unlinkedMsg =
            'This field was unlinked because this document is not associated with a property';
        } else if (isTransaction) {
          linkStatusDescription = linkLabel || null;
          unlinkedMsg = 'This has been unlinked from a transaction field';
          linkStatusAction = 'Relink';
        } else if (typeDescription) {
          linkStatusDescription = `This was a ${typeDescription} field`;
          unlinkedMsg = 'Field type has been removed';
          linkStatusAction = 'Add back field type';
        } else if (isLinked === false) {
          unlinkedMsg = 'Field behavior has been removed';
          linkStatusAction = 'Add back field behavior';
        }

        const icon = (
          <AppIcon
            key="link-icon"
            type="feather"
            size={12}
            name="more-vertical"
            className={classNames(`${linkMoreClsPrefix}--icon`)}
          />
        );

        return (
          <div
            className={classNames(
              `${linkMoreClsPrefix}`,
              `${linkMoreClsPrefix}--${left ? 'left' : 'right'}`,
              {
                [`${linkMoreClsPrefix}--unlinked`]: isLinked === false,
                [`${linkMoreClsPrefix}--fill-condition`]: missingFillCondition,
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            role="button"
          >
            <PspdfkitPopover
              mouseEnterDelay={0.2}
              mouseLeaveDelay={0.2}
              content={({ onClose }) => (
                <div className={unlinkModalPrefix}>
                  {(linkStatusDescription || unlinkedMsg) && (
                    <div className={`${unlinkModalPrefix}__description`}>
                      <span className={`${unlinkModalPrefix}__title`}>
                        {linkStatusDescription}
                      </span>
                      <span className={`${unlinkModalPrefix}__unlinked-msg`}>
                        {unlinkedMsg}
                      </span>
                    </div>
                  )}
                  <div className={`${unlinkModalPrefix}__actions`}>
                    {isLinked !== null && linkStatusAction && (
                      <AnchorButton
                        type="primary"
                        className={`${unlinkModalPrefix}__action-link`}
                        onClick={(e) => {
                          annotations
                            .getBoundOutput(this.annotation)
                            .setIsUnlinked(isLinked);
                          if (!isLinked) {
                            e.stopPropagation();
                          }
                          onClose();
                        }}
                      >
                        {linkStatusAction}
                      </AnchorButton>
                    )}
                    <AnchorButton
                      type="primary"
                      className={`${unlinkModalPrefix}__action-link`}
                      onClick={(e) => {
                        e.stopPropagation();
                        annotations.setClauseTarget(this.annotation);
                      }}
                    >
                      Insert clause
                    </AnchorButton>
                    {/*
                    Only show the option to edit legal name settings if there is a bound primary agent.
                    This is b/c the settings for the transaction are determined by that user's (or their org's) transaction settings.
                    */}
                    {isName && primaryAgent?.isBound && (
                      <AnchorButton
                        type="primary"
                        className={`${unlinkModalPrefix}__action-link`}
                        onClick={(e) => {
                          e.stopPropagation();
                          annotations.openLegalNameSettings();
                          onClose();
                        }}
                      >
                        Entity name display settings
                      </AnchorButton>
                    )}
                    <AnchorButton
                      type="primary"
                      className={`${unlinkModalPrefix}__action-link`}
                      onClick={(e) => {
                        e.stopPropagation();
                        annotations.setFeedbackForm(this.annotation);
                        onClose();
                      }}
                    >
                      Give feedback
                    </AnchorButton>
                  </div>
                </div>
              )}
              trigger="hover"
            >
              {icon}
            </PspdfkitPopover>
          </div>
        );
      }

      renderDesignMode(component) {
        const { annotations } = this.props;
        const { selectedAnnotations, isDesignMode } = annotations;
        const { customData } = this.annotation;
        const { children } = component.props;

        const newChildren = [];
        if (children instanceof Array) {
          newChildren.push(...children);
        } else {
          newChildren.push(children);
        }

        const formFieldName = customData?.formFieldName;
        const optional = customData?.optional ?? false;
        const mod = customData?.type ?? false;
        const selectedAnnotation =
          selectedAnnotations.length && selectedAnnotations[0];
        const selectedFormFieldName =
          selectedAnnotation?.customData?.formFieldName;
        const highlighted = selectedFormFieldName === formFieldName;
        const relatedAnnotations =
          annotations.getAnnotationsByFormField(formFieldName);

        const link = [FORM_NAMESPACE, TRANSACTION_NAMESPACE].includes(
          this.annotation.customData?.formFieldLinkNamespace
        );
        const brokenLink =
          link &&
          get(this.annotation, 'customData.formFieldLinkNamespace', null) ===
            FORM_NAMESPACE &&
          annotations.getAnnotationsByFormField(
            get(this.annotation, 'customData.formFieldLinkId', '')
              .replace('--autolinked', '')
              .replace(/!.*$/, '')
          ).length <= 0;

        if (link) {
          newChildren.push(this.renderLinkIcon(brokenLink));
        }

        newChildren.push(
          this.renderHighlight({
            highlighted,
            mod,
          })
        );

        if (optionsWithDefaults.showAddButton) {
          const showAddButton =
            isDesignMode &&
            selectedFormFieldName === formFieldName &&
            (!relatedAnnotations.length ||
              relatedAnnotations[relatedAnnotations.length - 1].id ===
                this.annotation.id);

          newChildren.push(
            this.renderAddButton({
              showAddButton,
            })
          );
        }

        const newProps = {
          ...component.props,
        };

        let backgroundColor =
          relatedAnnotations.length > 1 && annotations.groupByColor
            ? annotations.getFormFieldColor(formFieldName)
            : DEFAULT_BG_COLOR;

        if (RECIPIENT_COLOR_MAP[customData.recipientRole]) {
          // Use the RECIPIENT_COLOR_MAP if available, to keep the tabs up to date with the new colors
          backgroundColor = RECIPIENT_COLOR_MAP[customData.recipientRole];
        } else if (customData.recipientColor) {
          backgroundColor = customData.recipientColor;
        }

        newProps.style = {
          ...(component.props.style ?? {}),
          backgroundColor: `${backgroundColor}DD`,
          ...(optional
            ? {
                backgroundClip: 'border-box',
                borderColor: getColor('app-alto'),
              }
            : {}),
        };

        return React.cloneElement(component, newProps, newChildren);
      }

      render() {
        const { annotations, ghost } = this.props;
        const component = super.render();

        if (ghost) {
          return component;
        }

        if (annotations.isFillMode) {
          return this.renderFillMode(component);
        }

        return this.renderDesignMode(component);
      }
    }

    PdfFormField.displayName = `PdfFormField(${getDisplayName(
      WrappedComponent
    )})`;

    return selectable(false)(PdfFormField);
  };
}
