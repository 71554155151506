import { getContactForRecipient } from 'src/models/transactions/recipients';
import { getColor } from 'src/utils/get-color';

export const TOGGLE_SIZES = window.Glide?.CONSTANTS?.INGESTED_ANNOTATIONS_SIZES
  ?.TOGGLE ?? {
  NORMAL: {
    width: 10,
    height: 10,
    fontSize: 8,
  },

  LARGE: {
    width: 15,
    height: 15,
    fontSize: 12,
  },
};

export const DEFAULT_FONT_SIZE = 8;

const {
  SIGNATURE_TABS: SIGNATURE_TAB_SIZE = {
    width: 52,
    height: 34,
  },
  INITIALS_TAB: INITIALS_TAB_SIZE = {
    width: 34,
    height: 40,
  },
  DATE_TAB: DATE_TAB_SIZE = {
    width: 66,
    height: 14,
  },
} = window.Glide?.CONSTANTS?.INGESTED_ANNOTATIONS_SIZES ?? {};

export const SHARED_WIDGETS = {
  radio: {
    menuLabel: 'Radio',
    annotationLabel: 'Radio',
    icon: 'check-circle',
    sizes: TOGGLE_SIZES,
    ...TOGGLE_SIZES.NORMAL,
  },
  checkbox: {
    menuLabel: 'Checkbox',
    annotationLabel: 'Checkbox',
    icon: 'check-square',
    sizes: TOGGLE_SIZES,
    ...TOGGLE_SIZES.NORMAL,
  },
  text: {
    menuLabel: 'Text',
    annotationLabel: 'Text',
    icon: 'font-size',
    width: 50,
    height: 12,
  },
};

export const SIGNATURE_TABS = {
  'signature-tab': {
    menuLabel: 'Signature',
    annotationLabel: 'Sign',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/sign.svg'),
    },
    ...SIGNATURE_TAB_SIZE,
  },

  'initials-tab': {
    menuLabel: 'Initials',
    annotationLabel: 'Initials',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/initials_compass.svg'),
    },
    ...INITIALS_TAB_SIZE,
  },

  'date-tab': {
    menuLabel: 'Date Signed',
    annotationLabel: 'Date Signed',
    icon: {
      type: 'antd',
      name: 'calendar',
    },
    ...DATE_TAB_SIZE,
  },
};

export const SIGNATURE_TYPE_LABELS = {
  'signature-tab': 'Sign',
  'initials-tab': 'Initials',
  'date-tab': 'Date Signed',
};

export const WIDGETS = {
  ...SHARED_WIDGETS,
  dropdown: {
    menuLabel: 'Dropdown',
    annotationLabel: 'Dropdown',
    icon: 'unordered-list',
    width: 50,
    height: 12,
  },
};

const PDF_STRIKEOUT_WIDGETS = {
  'pdf-strikeout-line': {
    menuLabel: 'Add Line',
    annotationLabel: 'Add Line',
    icon: {
      type: 'svg',
      importSvg: () =>
        import('!!svgJsx!src/images/icons/annotate-line-white.svg'),
    },
    width: 50,
    height: 12,
  },
  'pdf-strikeout-text': {
    menuLabel: 'Strikethrough Text',
    annotationLabel: 'Strikethrough Text',
    icon: {
      type: 'svg',
      importSvg: () =>
        import('!!svgJsx!src/images/icons/annotate-strikethrough-white.svg'),
    },
    width: 50,
    height: 12,
  },
};

// Singular widgets since they will be reused in multiple collections.
// They're objects so that the 'type' key is always the same.
const NAME_WIDGET = {
  name: {
    menuLabel: 'Name',
    annotationLabel: 'Name',
    icon: 'user',
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return `${contact?.firstName} ${contact?.lastName}`.trim() || '';
    },
  },
};

const EMAIL_WIDGET = {
  email: {
    menuLabel: 'Email',
    annotationLabel: 'Email',
    icon: 'mail',
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return contact?.email || '';
    },
  },
};

const PHONE_WIDGET = {
  phone: {
    menuLabel: 'Phone',
    annotationLabel: 'Phone',
    icon: 'phone',
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return contact?.cellPhone || contact?.officePhone || '';
    },
  },
};

const COMPANY_WIDGET = {
  company: {
    menuLabel: 'Company',
    annotationLabel: 'Company',
    icon: {
      type: 'feather',
      name: 'briefcase',
    },
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return (
        contact?.reAgent?.companyName ||
        contact?.titleAgent?.companyName ||
        contact?.escrowAgent?.companyName ||
        contact?.lender?.companyName ||
        ''
      );
    },
  },
};

// Per Role widgets
export const CLIENT_WIDGETS = {
  ...NAME_WIDGET,
  ...EMAIL_WIDGET,
  ...PHONE_WIDGET,
  title: {
    menuLabel: 'Title',
    annotationLabel: 'Title',
    icon: {
      type: 'feather',
      name: 'file-text',
    },
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return contact?.title || '';
    },
  },
  'entity-name': {
    menuLabel: 'Entity Name',
    annotationLabel: 'Entity Name',
    icon: 'team',
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return contact?.entityName || '';
    },
  },
};

export const AGENT_BROKER_TEAM_WIDGETS = {
  ...NAME_WIDGET,
  ...EMAIL_WIDGET,
  ...PHONE_WIDGET,
  ...COMPANY_WIDGET,
  license: {
    menuLabel: 'License',
    annotationLabel: 'License',
    icon: 'number',
    width: 50,
    height: 12,
    defaultValueFromRecipient: (recipient) => {
      const contact = getContactForRecipient(recipient);
      return (
        contact?.reAgent?.licenseNumber ||
        contact?.titleAgent?.licenseNumber ||
        contact?.escrowAgent?.licenseNumber ||
        contact?.lender?.licenseNumber ||
        ''
      );
    },
  },
};

export const OTHER_WIDGETS = {
  ...NAME_WIDGET,
  ...EMAIL_WIDGET,
  ...COMPANY_WIDGET,
};

// All widgets
export const ALL_WIDGETS = {
  ...WIDGETS,
  ...PDF_STRIKEOUT_WIDGETS,
  ...CLIENT_WIDGETS,
  ...AGENT_BROKER_TEAM_WIDGETS,
  ...OTHER_WIDGETS,
};

export const SIGNATURE_PREP_WIDGETS = {
  ...ALL_WIDGETS,
  'pdf-strikeout-line': {
    ...ALL_WIDGETS['pdf-strikeout-line'],
    menuLabel: 'Line',
    annotationLabel: 'Line',
  },
};

export const ZONES = {
  'signature-zone': {
    menuLabel: 'Signature',
    annotationLabel: 'Sign',
    icon: 'edit',
    width: 200,
    height: 34,
  },

  'initials-zone': {
    menuLabel: 'Initials',
    annotationLabel: 'Initials',
    icon: 'italic',
    width: 50,
    height: 40,
  },

  'date-zone': {
    menuLabel: 'Date',
    annotationLabel: 'Date',
    icon: 'calendar',
    width: 80,
    height: 14,
  },

  'text-zone': {
    menuLabel: 'Text',
    annotationLabel: 'Text',
    icon: 'font-size',
    width: 50,
    height: 12,
  },

  'checkbox-zone': {
    menuLabel: 'Checkbox',
    annotationLabel: 'Checkbox',
    icon: 'check-square',
    width: 10,
    height: 10,
  },

  'section-zone': {
    menuLabel: 'Section',
    annotationLabel: 'Section',
    icon: 'unordered-list',
    width: 200,
    height: 50,
  },
};

export const PDF_ANNOTATIONS = {
  'pdf-stamp': {
    menuLabel: 'Add Stamp',
    annotationLabel: 'Stamp',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/annotate-stamp.svg'),
    },
    width: 140,
    height: 110,
    defaultValue: 'Received',
    fontSize: 10,
  },
  'pdf-text': {
    menuLabel: 'Add Text',
    annotationLabel: 'Text',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/annotate-text.svg'),
    },
    width: 80,
    height: 12,
    defaultValue: '',
    fontSize: 8,
  },
  'pdf-checkbox': {
    menuLabel: 'Add Checkbox',
    annotationLabel: 'Checkbox',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/check-square.svg'),
    },
    width: 12,
    height: 12,
    fontSize: 8,
  },
  'pdf-strikeout-text': {
    menuLabel: 'Strikethrough Text',
    annotationLabel: 'Strikethrough Text',
    icon: {
      type: 'svg',
      importSvg: () =>
        import('!!svgJsx!src/images/icons/annotate-strikethrough.svg'),
    },
    width: 50,
    height: 12,
  },
  'pdf-redact-text': {
    menuLabel: 'Redact Text',
    annotationLabel: 'Text Redaction',
    icon: {
      type: 'svg',
      importSvg: () =>
        import('!!svgJsx!src/images/icons/annotate-redact-text.svg'),
    },
    width: 50,
    height: 12,
  },
  'pdf-strikeout-line': {
    menuLabel: 'Add Line',
    annotationLabel: 'Add Line',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/annotate-line.svg'),
    },
    width: 100,
    height: 10,
  },
  'pdf-redact-area': {
    menuLabel: 'Draw black box',
    annotationLabel: 'Area Redaction',
    icon: {
      type: 'svg',
      importSvg: () => import('!!svgJsx!src/images/icons/annotate-redact.svg'),
    },
    width: 100,
    height: 50,
  },
};

export const TEXT_SPECIAL_FIELDS = {
  'addendum-field': {
    menuLabel: 'Addendum Field',
    annotationLabel: 'Addendum Field',
    icon: 'snippets',
    width: 108,
    height: 12,
  },
};

export const SPECIAL_FIELDS = {
  'filestack-image': {
    menuLabel: 'Brokerage Image',
    annotationLabel: 'Brokerage Image',
    icon: 'file-image',
    width: 108,
    height: 20,
  },
  ...TEXT_SPECIAL_FIELDS,
};

export const CLIENT_WIDGETS_TYPES = Object.keys(CLIENT_WIDGETS);
export const AGENT_BROKER_TEAM_WIDGETS_TYPES = Object.keys(
  AGENT_BROKER_TEAM_WIDGETS
);
export const OTHER_WIDGETS_TYPES = Object.keys(OTHER_WIDGETS);

// Text widgets that render the information about the recipient
export const RECIPIENT_DATA_RENDER_TYPES = [
  ...CLIENT_WIDGETS_TYPES,
  ...AGENT_BROKER_TEAM_WIDGETS_TYPES,
  ...OTHER_WIDGETS_TYPES,
];

export const TEXT_ANNOTATION_TYPES = Object.keys({
  ...ALL_WIDGETS,
  ...TEXT_SPECIAL_FIELDS,
});

export const TEXT_RENDER_TYPES = [
  'text',
  ...RECIPIENT_DATA_RENDER_TYPES,
  ...Object.keys(TEXT_SPECIAL_FIELDS),
];

export const ANNOTATIONS = {
  ...SIGNATURE_TABS,
  ...ALL_WIDGETS,
  ...ZONES,
  ...PDF_ANNOTATIONS,
  ...SPECIAL_FIELDS,
};

export const TOGGLES = {
  radio: WIDGETS.radio,
  checkbox: WIDGETS.checkbox,
};

export const READONLY_WIDGET_TYPES = [
  'radio',
  'checkbox',
  'text',
  'pdf-strikeout-text',
  'pdf-strikeout-line',
];
export const NON_INSERTABLE_WIDGET_TYPES = [
  'pdf-strikeout-text',
  'pdf-strikeout-line',
];

export const TAB_FIELD_TYPES = (
  window.Glide?.CONSTANTS?.ANNOTATIONS?.FIELD_TYPES ?? []
).map((t) => t.toLowerCase().replace('-', '_'));

export const ANNOTATION_MARGIN_BOTTOM = 4;

export const DEFAULT_FILL_PERMISSIONS = [];

export const TEXT_FIELD_FONT_FAMILY = 'Helvetica, Arial';
export const TEXT_FIELD_FONT_WEIGHT = '600';
export const TEXT_FIELD_FONT_STYLE = 'italic';
export const TEXT_FIELD_FONT_SIZE = 8;
export const TEXT_FIELD_FONT_COLOR = getColor('widget-text-color');
export const TEXT_FIELD_MIN_FONT_SIZE = 6;
export const TEXT_FIELD_OVERFLOW_ERROR_TEXT =
  'Your text exceeds the length of this field. Please update your text.';
export const TEXT_FIELD_TOA_TEXT = '[...overflow]';

export const PDF_ANNOTATION_ROOT_CLASS = 'pdf-annotation-root';
export const PDF_PAGE_INSERT_MASK_CLASS = 'pdf-page-insert-mask';
export const PSPDFKIT_ROOT_NODE_CLASS = 'PSPDFKit-Root';

export const PDF_ANNOTATION_FILL_OVERLAY_ID = 'overlay-fill-item';

export const FILL_PERMISSIONS = {
  ACT_AS_ADMIN: 'Agent Team',
  ACT_AS_CLIENT: 'Client',
};

export const OCR_ZONE_TYPES = ['text-zone', 'checkbox-zone', 'date-zone'];

export const OCR_TYPE_TO_ZONE_TYPE = {
  text: 'text-zone',
  checkbox: 'checkbox-zone',
  radio: 'checkbox-zone',
};

export const ASSOCIATED_OCR_ZONE_SUFFIX = '--ocr';
