

import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tab from './tab';

export default class InitialsTab extends Component {
  static propTypes = {
    annotation: PropTypes.object,
    annotations: PropTypes.object,
  };

  render() {
    return (
      <Tab {...this.props}>
        <AppIcon
          className="pdf-annotation-tab__icon"
          type="svg"
          importSvg={() =>
            import('!!svgJsx!src/images/icons/initials_compass.svg')
          }
        />
      </Tab>
    );
  }
}
