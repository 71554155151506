

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import selectable from './selectable';
import { getColor } from 'src/utils/get-color';

const clsPrefix = 'pdf-annotation-tab';

const COLORS = {
  'date-zone': '#ffdeffbb',
  'initials-zone': '#e5ffdebb',
  'signature-zone': '#def2ffbb',
  'text-zone': '#def2ffbb',
  'checkbox-zone': getColor('app-tertiary-color'),
  'section-zone': '#fdfda9bb',
};

@selectable()
export default class Zone extends Component {
  static propTypes = {
    annotation: PropTypes.object,
    children: PropTypes.any.isRequired,
  };

  normalizeColor(sourceHexcolor) {
    let hexcolor = sourceHexcolor;
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split('')
        .map((hex) => hex + hex)
        .join('');
    }
    return `#${hexcolor}`;
  }

  render() {
    const { annotation, children } = this.props;
    const { customData } = annotation;
    const { type } = customData;
    const backgroundColor = this.normalizeColor(COLORS[type]);
    const altColor = `${backgroundColor.slice(0, 7)}`;
    const altColor2 = `${altColor}66`;
    const style = customData?.ocrEnabled
      ? {
          background: `repeating-linear-gradient(135deg, ${altColor}, ${altColor} 5px, ${altColor2} 5px, ${altColor2} 10px)`,
        }
      : {
          backgroundColor,
        };
    return (
      <div
        className={classNames(clsPrefix, `${clsPrefix}__zone`, {
          [`${clsPrefix}--${type}`]: type,
        })}
        style={style}
      >
        <div className={`${clsPrefix}__inner`}>{children}</div>
      </div>
    );
  }
}
