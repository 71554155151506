/* eslint-disable no-shadow */
import React, { Component } from 'react';
import type PdfAnnotationsStore from 'src/stores/pdf-annotations-store';

interface OcrZoneIndicatorProps {
  annotation: Annotation;
  annotations: PdfAnnotationsStore;
}

interface Annotation {
  customData: CustomData;
  boundingBox: BoundingBox;
}
interface BoundingBox {
  width: number;
  height: number;
}
interface CustomData {
  ocrEnabled?: boolean;
  ocrKeyTerm: string;
  ocrOffsetTop?: number;
  ocrOffsetRight?: number;
  ocrOffsetLeft?: number;
  ocrOffsetBottom?: number;
}

export default class OcrZoneIndicator extends Component<OcrZoneIndicatorProps> {
  render() {
    const {
      annotation: { boundingBox, customData },
      annotations: { isDesignMode },
    } = this.props;

    const {
      ocrEnabled,
      ocrOffsetTop = 0,
      ocrOffsetBottom = 0,
      ocrOffsetRight = 0,
      ocrOffsetLeft = 0,
    } = customData;

    return isDesignMode && ocrEnabled && boundingBox ? (
      <div
        style={{
          top: -1 * ocrOffsetTop,
          left: -1 * ocrOffsetLeft,
          height: boundingBox.height + ocrOffsetTop + ocrOffsetBottom,
          width: boundingBox.width + ocrOffsetRight + ocrOffsetLeft,

          background:
            'repeating-linear-gradient(135deg, #aaf6, #aaf6 5px, #aafc 5px, #aafc 10px)',
          position: 'fixed',
          borderRadius: 3,
          opacity: 0.8,
        }}
      />
    ) : null;
  }
}
