import React, { FC, useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import TransactionSettingsFormModal from 'src/components/account/transaction-settings-form-modal';
import type { RenderOptions } from 'src/models/fields/render-options';
import type AccountStore from 'src/stores/account-store';
import type PdfAnnotationsStore from 'src/stores/pdf-annotations-store';

interface InnerProps {
  account: AccountStore;
  annotations: PdfAnnotationsStore;
}

const TransactionSettingsModalWrapper: FC<InnerProps> = inject(
  'account',
  'annotations'
)(
  observer((props: InnerProps) => {
    const { account, annotations } = props;
    const {
      transaction,
      transaction: {
        parties: { primaryAgent },
      },
    } = annotations;
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
      account.requireOboPermissionConfirmation({
        userId: primaryAgent.userId,
        contact: toJS(primaryAgent.contact),
        onSuccess: () => setShowModal(true),
      });
    }, []);

    const onSuccess = (txnSettings: RenderOptions) => {
      annotations.setRenderOptions(txnSettings);
      closeModal();
    };

    const closeModal = () => {
      annotations.hideLegalNameSettings();
    };

    if (!showModal) {
      return null;
    }

    return (
      <TransactionSettingsFormModal
        userId={primaryAgent.userId}
        showOboAlertForAgent={primaryAgent.isMe ? null : primaryAgent}
        onCancel={closeModal}
        onSuccess={onSuccess}
        updateCacheForTransactionId={transaction.id}
      />
    );
  })
);

export default TransactionSettingsModalWrapper as FC;
