

import React, { Component } from 'react';
import selectable from './selectable';

@selectable()
export default class PdfRedactArea extends Component {
  render() {
    return (
      <div
        className="pdf-annotation-base"
        style={{
          backgroundColor: '#000',
        }}
      />
    );
  }
}
