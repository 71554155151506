

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import selectable from './selectable';

const clsPrefix = 'pdf-annotation-checkbox-annotate';

@selectable()
export default class PdfCheckbox extends Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    annotations: PropTypes.object.isRequired,
  };

  get annotation() {
    const { annotations, annotation: _annotation } = this.props;
    return annotations.getAnnotationById(_annotation.id) ?? _annotation;
  }

  render() {
    return <div className={clsPrefix}>x</div>;
  }
}
