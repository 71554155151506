

import React, { Component } from 'react';
import { Avatar, Select } from 'antd';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  getContactForRecipient,
  getRoles,
} from 'src/models/transactions/recipients';
import { getRoleLabelsForTransaction } from 'src/models/transactions/roles';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';
import { getColor } from 'src/utils/get-color';
import {
  EXTRA_ROLES,
  RECIPIENT_COLOR_MAP,
  REGULAR_ROLES,
} from './recipient-role-select';


const clsPrefix = 'tab-recipient-select';

const AUTO_TAB_ROLES = [...REGULAR_ROLES, ...Object.keys(EXTRA_ROLES)];

const BASE_RECIPIENT_COLOR_LIST = [
  // Ignore the last 6 placeholder colors since they are just shades of gray.
  ...Object.values(RECIPIENT_COLOR_MAP).slice(0, -6),
  // Provided by https://app.clickup.com/t/pq8f34
  '#D5EDDC',
  getColor('yellow-8'),
  '#ECD4EC',
  '#FFD199',
  '#C8D6F9',
];

const adjustHexColor = (hexColor, amount) => {
  /*
   * Adjust each color channel by 'amount' where amount is a decimal calculated in 256 like rgb.
   * This does not account for any hex shortening, you must provide a full hex string.
   *
   * Source: https://stackoverflow.com/a/57401891
   */
  if (!hexColor.startsWith('#') || hexColor.length !== 7) {
    throw Error(`Invalid hex color: ${hexColor}`);
  }

  const adjustSingularHexColor = (color) =>
    `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
      16
    )}`.substr(-2);

  return `#${hexColor
    .replace(/^#/, '')
    .replace(/../g, adjustSingularHexColor)}`;
};

/*
 * This component is used by the customer facing esign page and the admin esign page
 * On the customer facing page this.props.recipients will be defined. It won't be on the admin page.
 */
@inject('annotations')
export default class RecipientSelect extends Component {
  static propTypes = {
    annotations: PropTypes.object.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    recipients: PropTypes.array,
    transaction: PropTypes.object,
    disabled: PropTypes.bool,
  };

  get labelByRoles() {
    const { transaction, recipients } = this.props;
    const labelByRoles = getRoleLabelsForTransaction(transaction);

    if (transaction && recipients.length) {
      return {
        ...labelByRoles,
        ...EXTRA_ROLES,
      };
    }
    return {
      ...Object.fromEntries(
        Object.entries(labelByRoles).filter(([key]) => {
          return REGULAR_ROLES.includes(key);
        })
      ),
      ...EXTRA_ROLES,
    };
  }

  get options() {
    // Get options, fairly costly.
    const {
      recipients,
      annotations: { allAnnotations },
    } = this.props;
    const options = {};
    const labelByRoles = this.labelByRoles;

    if (!recipients) {
      Object.entries(labelByRoles).forEach(([key, label]) => {
        options[key] = {
          label,
          color: RECIPIENT_COLOR_MAP[key],
        };
      });
    } else {
      // Get all recipientKeys and their color from the form so that we can maintain consistency
      const preAssignedColors = Array.from(allAnnotations)
        .filter((annotation) => annotation?.customData?.recipientColor)
        .reduce((agg, annotation) => {
          const cd = annotation.customData;
          agg[`${cd.recipientRole}:${cd.formFieldOwnerId}`] = cd.recipientColor;
          return agg;
        }, {});

      const availableRoles = Object.keys(labelByRoles);
      let nonAutoTabOptions = 0;
      recipients.forEach((recipient) => {
        const contact = getContactForRecipient(recipient) ?? null;
        const fullName = getFullNameOrEmail(contact);
        const roles = getRoles(recipient);

        if (!roles.length) {
          const idx = availableRoles.findIndex((r) => {
            return /PLACEHOLDER[1-6]/.test(r);
          });
          roles.push(availableRoles[idx]);
          availableRoles.splice(idx, 1);
        }

        roles.forEach((role) => {
          const label = `${fullName} (${labelByRoles[role]})`;
          const optionKey = `${role}:${recipient.key}`;
          let color = RECIPIENT_COLOR_MAP[role];

          if (!AUTO_TAB_ROLES.includes(role)) {
            if (optionKey in preAssignedColors) {
              color = preAssignedColors[optionKey];
            } else {
              do {
                nonAutoTabOptions += 1;
                // Loop through BASE_RECIPIENT_COLOR_LIST unlimited times.
                const colorToAdjust =
                  BASE_RECIPIENT_COLOR_LIST[
                    nonAutoTabOptions % BASE_RECIPIENT_COLOR_LIST.length
                  ];
                // Adjust to darken. We get the iteration number and multiply that by -20.
                // 2021-07-30: this gives us 134 colors. Sample: https://jsfiddle.net/mvanderlee/ce9q7g1k/5/
                color = adjustHexColor(
                  colorToAdjust,
                  Math.ceil(
                    nonAutoTabOptions / BASE_RECIPIENT_COLOR_LIST.length
                  ) * -20
                );
              } while (Object.values(preAssignedColors).includes(color));
            }
          }

          options[optionKey] = {
            role,
            recipient,
            label,
            color,
          };
        });
      });
    }

    return options;
  }

  componentDidMount() {
    const props = this.props;
    if (props.value) {
      this.initializeAnnotations();
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (!prevProps.value && props.value) {
      this.initializeAnnotations();
    }
  }

  initializeAnnotations = () => {
    const { onChange, value } = this.props;
    if (onChange && value) {
      const selectedOption = this.options[value];
      if (selectedOption) {
        onChange(
          selectedOption.role,
          selectedOption.recipient,
          selectedOption.color
        );
      }
    }
  }

  renderOptions = (options) => {
    return Object.entries(options).map(([key, { label, color }]) => {
      return (
        <Select.Option
          key={key}
          value={key}
          style={{
            height: '100%',
            lineHeight: '100%',
          }}
        >
          <div className={`${clsPrefix}__value-container`}>
            <Avatar
              style={{
                backgroundColor: color,
              }}
              size="small"
            />
            {label}
          </div>
        </Select.Option>
      );
    });
  };

  render() {
    const {
      value,
      style,
      className,
      label,
      onChange,
      disabled,
      annotations,
    } = this.props;
    const options = this.options; // Only calculate options once per render.
    // This exists to that when we go back and forth between pages, the colors dont' get stuck.
    // Would've prefered to use 'componentDidMount', but that isn't called in that flow.
    // Seem the component stays mounted for some reason.
    annotations.setRecipientColor(this.options[value]?.color);

    return (
      <div className={`${clsPrefix}__container`}>
        {label && <p>{label}</p>}
        <Select
          dropdownClassName={`${clsPrefix}__dropdown`}
          className={classNames(clsPrefix, className)}
          style={style}
          value={value}
          onChange={(selection) => {
            if (onChange) {
              const selectedOption = options[selection];
              onChange(
                selectedOption.role,
                selectedOption.recipient,
                selectedOption.color
              );
            }
          }}
          disabled={disabled}
        >
          {this.renderOptions(options)}
        </Select>
      </div>
    );
  }
}
