

import AnchorButton from 'src/components/common/anchor-button';
import AppFormModal from 'src/components/forms/app-form-modal';
import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import { QuickForm } from 'src/components/forms/quick-form';
import { Required } from 'src/components/forms/quick-form/validation';
import { inject, observer } from 'mobx-react';

const { TextArea } = Input;

const clsPrefix = 'app-annotation-feedback-modal';

@inject('ui', 'account', 'annotations', 'transactions')
@observer
class AnnotationFeedbackModal extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    annotations: PropTypes.object.isRequired,
    transactions: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  handleSave = async ({ text }) => {
    const { ui, account, annotations, transactions } = this.props;
    const {
      transaction,
      formId,
      feedbackFormAction,
      feedbackFormTarget,
    } = annotations;

    const page = feedbackFormTarget.pageIndex || 0;
    transactions.emit(transaction.id, [
      {
        event_key: 'forms.annotation_feedback',
        data: {
          text,
          page,
          annotation_id: feedbackFormTarget.id,
          form_id: formId,
          field_id: feedbackFormTarget.customData.formFieldName,
          user_id: account.user.id,
          party_id: transaction.parties.me?.id,
          action: feedbackFormAction,
        },
      },
    ]);
    ui.toast({
      type: 'success',
      message: 'Feedback sent!',
      description: 'Thank you for helping make Glide forms better.',
    });
    annotations.resetFeedbackForm();
  };

  render() {
    const { className, annotations, ...modalProps } = this.props;
    const cancelText = annotations.feedbackFormAction
      ? 'Not Right Now'
      : 'Cancel';
    return (
      <AppFormModal
        title={
          <div className={`${clsPrefix}__title`}>
            <AppIcon name="thumbs-up" className={`${clsPrefix}__title-icon`} />
            Help us improve!
          </div>
        }
        className={classNames(clsPrefix, className)}
        {...modalProps}
        cancelText={cancelText}
        okText="Send Feedback"
        onCancel={annotations.resetFeedbackForm}
        visible
      >
        {({ formRef, onChange }) => (
          <React.Fragment>
            <p>
              Please let us know how we can improve this form field for you.
            </p>
            <QuickForm
              name="annotationFeedback"
              container="form"
              validation={{
                text: Required({
                  message: `Please leave feedback or press '${cancelText}' to skip.`,
                }),
              }}
              hideControls
              onSave={this.handleSave}
              formRef={formRef}
              onChange={onChange}
            >
              {() => (
                <QuickForm.Item
                  name="text"
                  label=""
                  className={`${clsPrefix}__form-item`}
                >
                  <TextArea autoFocus rows={2} placeholder="Your feedback" />
                </QuickForm.Item>
              )}
            </QuickForm>
            <p>
              This feedback form is for improvements only. If you have an urgent
              matter, please contact support.
            </p>
          </React.Fragment>
        )}
      </AppFormModal>
    );
  }
}

export default AnnotationFeedbackModal;
