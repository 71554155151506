import first from 'lodash/first';
import get from 'lodash/get';
import omit from 'lodash/omit';
import uuid from 'uuid/v4';
import { FIELD_OUT_SEP } from 'src/stores/pdf-annotations-store';
import { getPSPDFKit } from 'src/utils/pspdfkit';
import {
  ALL_WIDGETS,
  ANNOTATIONS,
  ANNOTATION_MARGIN_BOTTOM,
  DEFAULT_FILL_PERMISSIONS,
  DEFAULT_FONT_SIZE,
  PDF_ANNOTATIONS,
  SPECIAL_FIELDS,
  TOGGLES,
  TOGGLE_SIZES,
  ZONES,
} from './constants';

const getPosition = ({ store, positionOrAnnotation, type, forcePosition }) => {
  const defaults = {
    top: 30,
    left: 30,
    page: false,
    ...(type && ANNOTATIONS[type] ? ANNOTATIONS[type] : {}),
  };

  const position =
    positionOrAnnotation instanceof
    getPSPDFKit().Annotations.RectangleAnnotation
      ? {
          top:
            positionOrAnnotation.boundingBox.top +
            defaults.height +
            ANNOTATION_MARGIN_BOTTOM,
          left: positionOrAnnotation.boundingBox.left,
          page: positionOrAnnotation.pageIndex,
          width: get(positionOrAnnotation, 'boundingBox.width'),
          height: get(positionOrAnnotation, 'boundingBox.height'),
        }
      : {
          ...positionOrAnnotation,
          ...(Object.keys(TOGGLES).includes(type) && store && !forcePosition
            ? omit(TOGGLE_SIZES[store.toggleSize || 'NORMAL'], ['fontSize'])
            : {}),
        };

  return {
    ...defaults,
    ...position,
  };
};

const createRecipientCustomData = (recipientKey, type) => {
  const TAB_TYPE_MAP = {
    'signature-tab': 'fill_signature',
    'initials-tab': 'fill_initials',
    'date-tab': 'fill_timestamp',
  };

  const formFieldName =
    type === 'date-tab' ? `${uuid()}${FIELD_OUT_SEP}as_string` : uuid();

  if (TAB_TYPE_MAP[type]) {
    return {
      formFieldLinkNamespace: 'app',
      formFieldLinkId: TAB_TYPE_MAP[type],
      formFieldName,
      formFieldOwnerId: recipientKey,
    };
  }

  return {
    formFieldOwnerId: recipientKey,
  };
};

const getDefaultAnnotationValue = (type) => {
  let defaultValue;
  if (type in PDF_ANNOTATIONS) {
    defaultValue = get(PDF_ANNOTATIONS[type], 'defaultValue', undefined);
  }
  return defaultValue;
};

const createSpecialFieldCustomData = (type) => {
  if (type === 'filestack-image') {
    // TODO all filestack image annotations link to the brokerage
    // image. We need to untie this if we find other uses for
    // this annotation kind
    return {
      formFieldLinkNamespace: 'app',
      formFieldLinkId: 'brokerage-image',
    };
  }
  if (type === 'addendum-field') {
    return {
      formFieldLinkNamespace: 'app',
    };
  }
  return {};
};

const getFontSize = (type) => {
  let fontSize = DEFAULT_FONT_SIZE;
  if (type in PDF_ANNOTATIONS) {
    fontSize = get(PDF_ANNOTATIONS[type], 'fontSize', '8px');
  }
  return fontSize;
};

// insertAnnotations manages the insertion of a set of grouped annotations, ordered by
// the desired index. We can even insert a subset of grouped annotations, not
// contiguous in idex, and keep the order.
// Positions can be an array of positions, as many as amount param, or with just one
// for the case where we have a single reference for multiple annotations
// referenceAnnotations can be an array of annotations, as many as amount param, or with
// just one for the case where we have a single reference for multiple annotations
export const insertAnnotations = ({
  id,
  store,
  pspdfkitInstance,
  page,
  positions,
  referenceAnnotations,
  customData,
  indexOffset,
  amount,
  type,
  force,
  recipientKey,
  keepParams = false,
  readOnly,
  optional,
  defaultValue,
  keepFormIndex = false,
}) => {
  if (!Object.keys(ANNOTATIONS).includes(type)) {
    throw new Error("Can't insert unknown type: ", type);
  }

  const marginBottom = ANNOTATION_MARGIN_BOTTOM;
  const isSpecialFormField = Object.keys(SPECIAL_FIELDS).includes(type);
  const isZoneField = Object.keys(ZONES).includes(type);
  const isFormField =
    Object.keys(ALL_WIDGETS).includes(type) ||
    isSpecialFormField ||
    isZoneField;
  const isToggle = Object.keys(TOGGLES).includes(type);
  const formFieldName = isFormField && uuid();
  // Not to be confused with fields defaultValue. This one
  // is used for pdf-annotations
  const defaultAnnotationValue = getDefaultAnnotationValue(type);

  const defaultRef =
    (positions && first(positions)) ||
    (referenceAnnotations && first(referenceAnnotations));

  const insertedAnnotations = [...new Array(amount || 1)].map((__, index) => {
    const ownRef =
      (positions && positions.length > index && positions[index]) ||
      (referenceAnnotations &&
        referenceAnnotations.length > index &&
        referenceAnnotations[index]);
    const { top, left, width, height } = getPosition({
      store,
      positionOrAnnotation: ownRef || defaultRef,
      type,
      forcePosition: force,
    });
    const newCustomData = {
      // form fields (aka widgets) need permissions, but they could
      // be overriden by the customData parameter (happens when importing
      // annotations for example)
      ...(isFormField
        ? {
            formFieldFillPermissions: DEFAULT_FILL_PERMISSIONS,
          }
        : {}),

      // the custom data param
      ...(customData || {}),

      // custom fontsize for toggles
      ...(isToggle && !force
        ? {
            fontSize:
              defaultRef?.customData?.fontSize ??
              TOGGLE_SIZES[store.toggleSize || 'NORMAL'].fontSize,
          }
        : {}),

      // we always override the type to match
      type,

      // if it's a form field we make sure the formFieldName is set and
      // that it has the correct index based on offset. Form field name
      // defaults to uuid
      ...(isFormField
        ? {
            formFieldName: get(customData, 'formFieldName', formFieldName),
            index: keepFormIndex
              ? customData.index
              : index + (indexOffset || 0),
            formFieldParams: keepParams ? customData?.formFieldParams : {},
          }
        : {}),

      // if the type merits an exportValue, add it
      ...(isToggle
        ? {
            exportValue: keepFormIndex
              ? customData.exportValue.toString()
              : `${index + (indexOffset || 0)}`,
          }
        : {}),
      ...(recipientKey ? createRecipientCustomData(recipientKey, type) : {}),
      // if the type has a default export value
      ...(defaultAnnotationValue !== undefined
        ? {
            exportValue: defaultAnnotationValue,
            fontSize: getFontSize(type),
          }
        : {}),

      ...(isSpecialFormField ? createSpecialFieldCustomData(type) : {}),

      readOnly,
      defaultValue,
      optional,
    };

    const pageIndex = first(
      [
        page,
        defaultRef?.pageIndex,
        pspdfkitInstance.viewState.currentPageIndex,
      ].filter((p) => p != null)
    );
    const pageInfo = pspdfkitInstance.pageInfoForIndex(pageIndex);

    return new (getPSPDFKit().Annotations.RectangleAnnotation)({
      id,
      pageIndex,
      boundingBox: new (getPSPDFKit().Geometry.Rect)({
        left: Math.max(0, Math.min(pageInfo.width - width, left)),
        top: Math.max(
          0,
          Math.min(
            pageInfo.height - height,
            top + (height + marginBottom) * (ownRef ? 0 : index)
          )
        ),
        width,
        height,
      }),
      customData: newCustomData,

      // hacky. Make these invisible in thumbnails and navigation
      ...(isSpecialFormField || isZoneField
        ? {
            strokeWidth: 0,
            strokeColor: getPSPDFKit().Color.WHITE,
          }
        : {}),
    });
  });
  return pspdfkitInstance.create(insertedAnnotations);
};
