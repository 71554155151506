

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import pdfFormField from './pdf-form-field';
import { getColor } from 'src/utils/get-color';
import OcrZoneIndicator from './ocr-zone-indicator';

const clsPrefix = 'pdf-annotation-toggle';

@pdfFormField()
export default class Toggle extends Component {
  static propTypes = {
    annotations: PropTypes.object.isRequired,
    annotation: PropTypes.object.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'radio',
  };

  get annotation() {
    const { annotation, annotations } = this.props;
    return annotations.getAnnotationById(annotation.id);
  }

  handleToggleValue = () => {
    const { annotations, type } = this.props;
    const curr = annotations.valueOf(this.annotation);
    const canFill = !annotations.isReadOnly(this.annotation);

    if (!canFill) {
      return;
    }

    if (type === 'radio') {
      annotations.setValue(
        this.annotation,
        this.getIsSelected(curr) ? null : this.annotation.customData.exportValue
      );
    } else if (type === 'checkbox') {
      annotations.setValue(this.annotation, !this.getIsSelected(curr));
    }
  };

  getIsSelected(value) {
    const {
      type,
      annotations: { isDesignMode, guidingTextVisibility },
    } = this.props;

    if (isDesignMode && guidingTextVisibility) {
      return true;
    }

    let _value = value;
    if (isDesignMode) {
      _value = this.annotation.customData.defaultValue;
    }

    if (type === 'radio') {
      return _value === this.annotation.customData.exportValue;
    }
    if (type === 'checkbox') {
      return Boolean(_value);
    }

    return false;
  }

  render() {
    const { annotations, type } = this.props;
    const {
      isDesignMode,
      isFillMode,
      valueOf,
      missingFillConditionsMode,
      selectedMissingFillCondition,
      isHighlighted,
      guidingTextVisibility,
    } = annotations;
    const value = isFillMode ? valueOf(this.annotation) : null;
    const selected = this.getIsSelected(value);
    const readonly = annotations.isReadOnly(this.annotation);
    const fieldName = this.annotation?.customData?.formFieldName;
    let missingFillCondition = false;
    let isFillConditionSelected = false;
    if (missingFillConditionsMode) {
      missingFillCondition = annotations.isMissingField(fieldName);
      isFillConditionSelected =
        missingFillCondition &&
        selectedMissingFillCondition?.fieldName === fieldName;
    }
    return (
      <div
        role="radio"
        aria-checked={selected}
        className={classNames(clsPrefix, `${clsPrefix}--${type}`, {
          [`${clsPrefix}--readonly`]: readonly,
          [`${clsPrefix}--fill-mode`]: isFillMode,
          [`${clsPrefix}--fill-condition`]: missingFillCondition,
          [`${clsPrefix}--fill-condition-selected`]: isFillConditionSelected,
          [`${clsPrefix}--highlighted`]: isHighlighted(this.annotation),
        })}
        onClick={isFillMode ? this.handleToggleValue : null}
      >
        <div
          className={`${clsPrefix}__checkmark`}
          style={{
            display: selected ? 'block' : 'none',
            opacity: isDesignMode && guidingTextVisibility ? 0.6 : 1,
          }}
        >
          <svg
            viewBox="0 0 18 18"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid meet"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <text x="4.5" y="14.1" fill={getColor('widget-text-color')}>
              X
            </text>
          </svg>
        </div>
        <OcrZoneIndicator {...this.props} />
      </div>
    );
  }
}
