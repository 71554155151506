

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Zone from './zone';

export default class SignatureZone extends Component {
  static propTypes = {
    annotation: PropTypes.object,
    annotations: PropTypes.object,
  };

  render() {
    return (
      <Zone {...this.props}>
        <div
          className="pdf-annotation-tab__label"
          style={{
            paddingTop: '2px',
          }}
        >
          Signature
        </div>
      </Zone>
    );
  }
}
