

import selectable from './selectable';
import React, { Component } from 'react';

const clsPrefix = 'pdf-annotate-strikeout-line';

@selectable()
export default class PdfStrikeoutLine extends Component {
  constructor(props) {
    super(props);
    this.divElement = React.createRef();
  }

  render() {
    let height = 1;
    if (this.divElement && this.divElement.current) {
      height = Math.max(
        1,
        (4 / 100) * this.divElement.current.getBoundingClientRect().height
      );
    }
    return (
      <div className={clsPrefix} ref={this.divElement}>
        <div
          className={`${clsPrefix}__strikeout`}
          style={{ height: `${height}px` }}
        />
      </div>
    );
  }
}
