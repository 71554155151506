

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import selectable from './selectable';

const clsPrefix = 'pdf-annotation-image';

@selectable()
export default class FilestackImage extends Component {
  static propTypes = {
    annotations: PropTypes.object,
    annotation: PropTypes.object,
  };

  render() {
    const { annotations, annotation } = this.props;
    const { isDesignMode, isPreviewDesignMode, valueOf } = annotations;
    const value = valueOf(annotation);

    if (isDesignMode || isPreviewDesignMode) {
      return (
        <div className={clsPrefix}>
          <AppIcon type="antd" name="file-image" />
        </div>
      );
    }

    if (value?.url) {
      return (
        <div className={classNames(clsPrefix, `${clsPrefix}--with-logo`)}>
          <img className={`${clsPrefix}__logo`} src={value.url} alt="Logo" />
        </div>
      );
    }

    return null;
  }
}
