

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Zone from './zone';

export default class CheckboxZone extends Component {
  static propTypes = {
    annotation: PropTypes.object,
    annotations: PropTypes.object,
  };

  render() {
    return (
      <Zone {...this.props}>
        <div className="pdf-annotation-tab__label" />
      </Zone>
    );
  }
}
