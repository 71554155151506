

import React, { Component } from 'react';
import selectable from './selectable';

const clsPrefix = 'pdf-annotation-unknown';

@selectable()
export default class UnknownAnnotation extends Component {
  render() {
    return <div className={clsPrefix} />;
  }
}
