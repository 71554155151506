

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import selectable from './selectable';

@selectable()
export default class PdfText extends Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    annotations: PropTypes.object.isRequired,
  };

  get annotation() {
    const { annotations, annotation: _annotation } = this.props;
    return annotations.getAnnotationById(_annotation.id) ?? _annotation;
  }

  render() {
    const {
      annotation: { customData, id },
    } = this;
    const exportValue = customData?.exportValue ?? '';
    const fontSize = customData?.fontSize ?? 8;
    const empty = !exportValue;
    return (
      <div
        className="pdf-annotation-base"
        style={{
          textAlign: 'left',
          fontSize: `${fontSize}px`,
          lineHeight: `${fontSize}px`,
        }}
      >
        <div
          id={`pdf-annotation-text-${id}`}
          style={
            empty
              ? {
                  color: '#999',
                }
              : {
                  wordWrap: 'break-word',
                }
          }
        >
          {empty ? 'EMPTY TEXT' : exportValue}
        </div>
      </div>
    );
  }
}
